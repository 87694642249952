var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Resumen ventas")]),_c('v-spacer'),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Desde","outlined":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.fecha_desde),callback:function ($$v) {_vm.fecha_desde=$$v},expression:"fecha_desde"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Hasta","outlined":"","hide-details":"","dense":"","type":"date"},model:{value:(_vm.fecha_hasta),callback:function ($$v) {_vm.fecha_hasta=$$v},expression:"fecha_hasta"}})],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listarResumenes()}}},[_vm._v("Buscar")])],1)],1)],1)]},proxy:true},{key:`item.fecha`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatoFecha(item.fecha)))])]}},{key:`item.total_pagar`,fn:function({ item }){return [_c('span',[_vm._v(" $ "+_vm._s(Number(item.total_pagar).toFixed(2)))])]}},{key:`item.estatus`,fn:function({ item }){return [(
          item.sello_recepcion == '' && item.resolucion_invalidacion == null
        )?_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fa-regular fa-floppy-disk")])]}}],null,true)},[_c('span',[_vm._v("GUARDADO")])]):(
          item.sello_recepcion != '' && item.resolucion_invalidacion == null
        )?_c('v-tooltip',{attrs:{"left":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v("fa-regular fa-circle-check")])]}}],null,true)},[_c('span',[_vm._v("PROCESADO")])]):(
          item.resolucion_invalidacion != '' &&
          item.resolucion_invalidacion != null
        )?_c('v-tooltip',{attrs:{"left":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("fa-solid fa-ban")])]}}],null,true)},[_c('span',[_vm._v("ANULADO")])]):(item.estatus == 'ERROR')?_c('v-tooltip',{attrs:{"left":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("fa-solid fa-circle-exclamation")])]}}],null,true)},[_c('span',[_vm._v("ERROR")])]):_vm._e()]}},(_vm.items.length > 0)?{key:`footer.prepend`,fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"green"},on:{"click":function($event){return _vm.generarExcel()}}},[_vm._v("Descargar")])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }