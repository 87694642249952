import Vue from 'vue'
import VueRouter from 'vue-router'

//ADMINISTRACION
import Empresas from '../components/Administracion/Empresas'
import Usuarios from "../components/Administracion/Usuarios"
import Roles from "../components/Administracion/Roles"
import PermisosModulos from "../components/Administracion/PermisosModulos"

//CATALOGOS
import Clientes from "../components/Catalogos/Clientes"
import Sucursales from "../components/Catalogos/Sucursales"
import Configuracion from "../components/Catalogos/Configuracion"
import Servicios from "../components/Catalogos/Servicios"
import Vendedores from "../components/Catalogos/Vendedores"
import Productos from "../components/Catalogos/Productos"
import ConfiguracionSMTP from "../components/Catalogos/ConfiguracionSMTP"
import Proveedores from "../components/Catalogos/Proveedor"

//OTROS
import Bienvenido from "../components/Bienvenido"
import Login from "../components/Login"
import Paises from "../components/Pais"
import ReporteCliente from "../components/ReporteClientes"
import ReporteProveedor from "../components/ReporteProveedores"
import Pagos from "../components/Pagos"
import ResumenVentas from "../components/ResumenVentas"
import ExportacionFacturas from "../components/ExportacionFacturas.vue"

import indexFe from "../components/Facturacion/index.vue"
import index from '@/components/Compras/index.vue'
import LibrosIVA from '@/components/LibrosIVA.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'bienvenido',
    component: Bienvenido
  },
  {
    path: '/exportacion_facturas',
    name: 'Exportacion Facturas',
    component: ExportacionFacturas
  },
  {
    path: '/resumen_ventas',
    name: 'ResumenVentas',
    component: ResumenVentas
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: Pagos
  },

  {
    path: '/reporte_clientes',
    name: 'Reporte clientes',
    component: ReporteCliente
  },
  {
    path: '/reporte_proveedores',
    name: 'Reporte proveedores',
    component: ReporteProveedor
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    component: Proveedores
  },
  {
    path: '/configuracion_smtp',
    name: 'configuracion smtp',
    component: ConfiguracionSMTP
  },
  {
    path: '/permisos_modulos',
    name: 'permisos modulos',
    component: PermisosModulos
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: Empresas
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: Usuarios
  },
  {
    path: "/roles",
    name: "roles",
    component: Roles
  },
  {
    path: "/clientes",
    name: "clientes",
    component: Clientes
  },
  {
    path: "/facturacion",
    name: "facturacion",
    component: indexFe
  },
  {
    path: "/compras",
    name: "compras",
    component: index
  },
  {
    path: "/paises",
    name: "paises",
    component: Paises
  },
  {
    path: "/sucursales",
    name: "sucursales",
    component: Sucursales
  },
  {
    path: "/configuracion",
    name: "configuracion",
    component: Configuracion
  },
  {
    path: "/servicios",
    name: "servicios",
    component: Servicios
  },
  {
    path: "/vendedores",
    name: "vendedores",
    component: Vendedores
  },
  {
    path: "/productos",
    name: "productos",
    component: Productos
  },
  {
    path: "/librosiva",
    name: "Libros de IVA",
    component: LibrosIVA
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
