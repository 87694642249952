<template>

  <v-container class="text-center">
    <v-row>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-col class="pa-17">
          <v-text-field v-model="anio" label="Año" outlined dense hide-details></v-text-field>
        </v-col>

        <v-col class="pa-17">
          <v-autocomplete v-model="mes" :items="items_mes" item-text="mes" item-value="id_mes" label="Mes" outlined
            dense hide-details>

          </v-autocomplete>
        </v-col>

        <v-col class="pa-17">
          <v-autocomplete v-model="sucursal" :items="items_sucursales" item-text="nombre" item-value="id_sucursal"
            label="Sucursal" outlined dense hide-details>
            <template v-slot:selection="data">
              {{ data.item.cod_sucursal }} {{ data.item.nombre }}
            </template>
            <template v-slot:item="data">
              {{ data.item.cod_sucursal }} {{ data.item.nombre }}
            </template>
          </v-autocomplete>
        </v-col>


        <v-dialog v-model="dialog_preview_report" fullscreen persistent>
          <v-toolbar flat dense>
            <v-toolbar-title> DOCUMENTO </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="dialog_preview_report = !dialog_preview_report">far fa-times-circle</v-icon>
          </v-toolbar>
          <iframe :src="base64_doc" id="myPDF" frameborder="0" />
        </v-dialog>


        <v-spacer></v-spacer>
      </v-toolbar>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" fab @click="descargarCCF">
          <img src="@/assets/doc_ccf.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" elevation="24" fab @click="descargarFAC">
          <img src="@/assets/doc_fac.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" elevation="24" fab @click="descargarCompras">
          <img src="@/assets/doc_com.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" fab @click="descargarFSE">
          <img src="@/assets/doc_sujex.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" fab @click="descargarPERC">
          <img src="@/assets/doc_perc.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" fab @click="descargarRET">
          <img src="@/assets/doc_ret.png" alt="Icono" class="button-image">
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">


      <v-col cols="auto">
        <v-btn class="ma-2" color="cyan" fab @click="descargarANUL">
          <img src="@/assets/doc_anulado.png" style="width: 80px; height: 80px">
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import axios from "axios";


export default {
  name: 'CircularButtons',
  data() {
    return {

      anio: "",
      mes: "",
      sucursal: "",

      //Requsitos
      tipoDocumento: "", // A 
      documento_identificador: "", // B 
      nombre: "", // C 
      fecha: "",  // D
      serie: "", // E 
      documento: "", // F 
      internas_importaciones: "", // G 
      iva: "", // H 
      tipo_operacion: "", // I 
      clasificacion: "",  // J 
      rubro: "", // K 
      costo_gastos: "", // L 
      numeroAnexo: "5", // M 

      dialog_preview_report: false,
      base64_doc: "",

      loading_compras: false,
      items_compras: [],
      items_sucursales: [],
      items_mes: [],
      items_fac: [],

      snackbar: false,
      snackbar_text: ""
    }
  },

  created() {
    this.listarSucursales();
    this.listarMes();
    this.listarProveedores();
    this.listarClientes();
    this.generarReporte();
  },

  methods: {


    getProveedorByIdLocal(id_proveedor) {
      if (this.items_proveedores && this.items_proveedores[id_proveedor]) {
        return this.items_proveedores[id_proveedor];
      }
      return null;
    },

    getClienteByIdLocal(id_cliente) {
      if (this.items_clientes && this.items_clientes[id_cliente]) {
        return this.items_clientes[id_cliente];
      }
      return null;
    },


    listarFacturas() {
      this.loading_facturas = true;
      axios
        .get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items = response.data;
          this.loading_facturas = false;
        })
        .catch(() => {
          this.loading_facturas = false;
        });
    },


    listarSucursales() {
      axios
        .get("api/Sucursales/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_sucursales = response.data;
          this.sucursal = response.data[0].id_sucursal;
        });
    },

    listarMes() {
      axios.get("api/Mes/Listar")
        .then((response) => {
          this.items_mes = response.data;
        });
    },

    // Listar todos los proveedores 
    listarProveedores() {
      return axios
        .get("api/Proveedores/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_proveedores = response.data.reduce((map, proveedor) => {
            map[proveedor.id_proveedor] = proveedor;
            return map;
          }, {});


        })
        .catch((error) => {
          console.error("Error al listar los proveedores:", error);
          this.snackbar_text = "No se pudieron cargar los proveedores";
          this.snackbar = true;
        });
    },

    listarClientes() {
      return axios
        .get("api/Clientes/ListarTodos/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_clientes = response.data.reduce((map, cliente) => {
            map[cliente.id_cliente] = cliente;
            return map;
          }, {});


        })
        .catch((error) => {
          console.error("Error al listar los clientes:", error);
          this.snackbar_text = "No se pudieron cargar los clientes";
          this.snackbar = true;
        });
    },



    // Método para descargar el archivo FSE 
    descargarFSE() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_compras = true;
        axios.get("api/Compras/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            if (Array.isArray(response.data)) {
              // Filtrar los datos solo los documentos de tipo "FSE"
              const fseData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                return item.cod_doc_fiscal === "FSE" &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              });


              fseData.forEach((item) => {
                const proveedor = this.getProveedorByIdLocal(item.id_proveedor);
                if (proveedor) {
                  item.nombre = proveedor.nombre || "No disponible";
                  item.documento_identificador = proveedor.documento_identificador
                    ? proveedor.documento_identificador.replace(/-/g, "")
                    : "No disponible";
                } else {
                  item.nombre = "Proveedor no encontrado";
                  item.documento_identificador = "No disponible";
                }
              });

              // Obtener los IDs de facturas
              const id_compra = fseData.map(item => item.id_compra);

              if (id_compra.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_compras: id_compra,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "LOCAL" // LOCAL o SERVER
                };


                axios.post("api/Facturas/ReporteFSE", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo generado
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reportes_FSE_${this.anio}_${this.mes}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte descargado exitosamente";
                    this.snackbar = true;
                  })
                  .catch(error => {
                    console.error("Error al generar el reporte CCF:", error);
                    this.snackbar_text = "No se pudo generar el reporte CCF";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });
              } else {
                console.warn("No hay documentos CCF para el año y mes seleccionados");
                this.snackbar_text = "No hay documentos CCF para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              console.error("Respuesta no esperada:", response.data);
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al descargar los documentos CCF:", error);
            this.snackbar_text = "No se pudo descargar el archivo CCF";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },

    descargarCCF() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_facturas = true;

        // Obtener las facturas
        axios.get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
          .then(response => {
            if (Array.isArray(response.data)) {
              // Filtrar los datos de CCF
              const ccfData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                return item.cod_doc_fiscal = "CCF" &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              }).map(item => {
                // Obtener cliente
                const cliente = this.getClienteByIdLocal(item.id_cliente);
                if (cliente) {
                  item.nit = cliente.id_identificador == "1" && cliente.documento
                    ? cliente.documento.replace(/-/g, "")
                    : "";

                  item.dui = cliente.id_identificador == "2" && cliente.documento
                    ? cliente.documento.replace(/-/g, "")
                    : "";

                  item.nombre = cliente.nombre || "No disponible";
                } else {
                  item.nit = "N/A";
                  item.dui = "N/A";
                  item.nombre = "Cliente no encontrado";
                }
                return item;
              });

              // Obtener los IDs de facturas
              const id_factura = ccfData.map(item => item.id_factura);

              if (id_factura.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_facturas: id_factura,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "LOCAL" // LOCAL o SERVER
                };


                axios.post("api/Facturas/ReporteCCF", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo generado
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reportes_CCF_${this.anio}_${this.mes}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte descargado exitosamente";
                    this.snackbar = true;
                  })
                  .catch(error => {
                    console.error("Error al generar el reporte CCF:", error);
                    this.snackbar_text = "No se pudo generar el reporte CCF";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });
              } else {
                console.warn("No hay documentos CCF para el año y mes seleccionados");
                this.snackbar_text = "No hay documentos CCF para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              console.error("Respuesta no esperada:", response.data);
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al descargar los documentos CCF:", error);
            this.snackbar_text = "No se pudo descargar el archivo CCF";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },



    descargarFAC() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_facturas = true;

        // Obtener facturas y clientes
        const facturasPromise = axios.get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa);
        const clientesPromise = axios.get("api/Clientes/Listar/" + this.$store.state.emp.id_empresa);

        Promise.all([facturasPromise, clientesPromise])
          .then(([facturasResponse, clientesResponse]) => {
            const facturasData = facturasResponse.data;


            const id_factura = facturasData
              .filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                return item.cod_doc_fiscal === "FAC" &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              })
              .map(factura => factura.id_factura);

            if (id_factura.length > 0) {
              // API
              const parametros = {
                id_facturas: id_factura,
                tipo_doc: "pdf", // DOC
                server: "LOCAL" // LOCAL o SERVER  

              };

              // endpoint
              axios.post("api/Facturas/ReporteFAC", parametros)
                .then(response => {
                  const reporteURL = response.data;

                  const csvContent = this.generarCSVFAC(facturasData);
                  const link = document.createElement('a');
                  link.href = reporteURL;
                  link.setAttribute('download', `Reporte_FAC_${this.anio}_${this.mes}.pdf`);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);

                  this.base64_doc = "data:application/pdf;base64," + response.data;
                  this.dialog_preview_report = true;

                  this.snackbar_text = "Reporte descargado exitosamente";
                  this.snackbar = true;
                })
                .catch(error => {
                  console.error("Error al generar el reporte FAC:", error);
                  this.snackbar_text = "No se pudo generar el reporte FAC";
                  this.snackbar = true;
                })
                .finally(() => {
                  this.loading_facturas = false;
                });
            } else {
              console.warn("No hay facturas disponibles para el reporte");
              this.snackbar_text = "No hay facturas disponibles para el reporte";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al obtener facturas o clientes:", error);
            this.snackbar_text = "No se pudo obtener información de facturas o clientes";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },



    descargarCompras() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_compras = true;

        axios.get("api/Compras/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            if (Array.isArray(response.data)) {
              
              const comprasData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                return itemAnio === parseInt(this.anio) && itemMes === parseInt(this.mes);
              }).map(compra => {
                
                const proveedor = this.getProveedorByIdLocal(compra.id_proveedor);
                if (proveedor) {
                  compra.nit_ncr = proveedor.id_identificador === "1"
                    ? proveedor.documento_identificador.replace(/-/g, "") 
                    : ""; 

                  compra.dui = proveedor.id_identificador = "2"
                    ? proveedor.documento_identificador.replace(/-/g, "") 
                    : ""; 

                  compra.proveedor = proveedor.nombre || "Sin Nombre";
                } else {
                  compra.nit_ncr = "N/A"; 
                  compra.dui = "N/A"; 
                  compra.proveedor = "Proveedor Desconocido"; 
                }
                return compra;
              });

              // Obtener los IDs de compras
              const id_compra = comprasData.map(item => item.id_compra);

              if (id_compra.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_compras: id_compra,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "LOCAL" // LOCAL o SERVER
                };


                axios.post("api/Facturas/ReporteCompra", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo generado
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reporte_Compra_${this.anio}_${this.mes}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte descargado exitosamente";
                    this.snackbar = true;
                  })
                  .catch(error => {
                    console.error("Error al generar el reporte Compra:", error);
                    this.snackbar_text = "No se pudo generar el reporte Compra";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });
              } else {
                console.warn("No hay documentos Compra para el año y mes seleccionados");
                this.snackbar_text = "No hay documentos Compra para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              console.error("Respuesta no esperada:", response.data);
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al descargar los documentos Compra:", error);
            this.snackbar_text = "No se pudo descargar el archivo Compra";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },


    descargarRET() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_facturas = true;
        axios.get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            if (Array.isArray(response.data)) {
              const retData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                // Documentos que poseen retención
                return item.retencion > 0 &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              }).map(item => {
                const cliente = this.getClienteByIdLocal(item.id_cliente);
                if (cliente) {

                  item.nit = cliente.id_identificador = "1"
                    ? cliente.documento_identificador
                    : "";

                  item.dui = cliente.id_identificador === "2"
                    ? cliente.documento_identificador
                    : "";

                  item.nombre = cliente.nombre || "No disponible";
                } else {
                  item.nit = "N/A";
                  item.dui = "N/A";
                  item.nombre = "Cliente no encontrado";
                }
                return item;
              });

              // Obtener los IDs de facturas
              const id_factura = retData.map(item => item.id_factura);

              if (id_factura.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_facturas: id_factura,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "LOCAL" // LOCAL o SERVER
                };


                axios.post("api/Facturas/ReporteRetencion", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo generado
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reportes_Retencion_${this.anio}_${this.mes}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte descargado exitosamente";
                    this.snackbar = true;
                  })
                  .catch(error => {
                    console.error("Error al generar el reporte:", error);
                    this.snackbar_text = "No se pudo generar el reporte";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });
              } else {
                console.warn("No hay documentos para el año y mes seleccionados");
                this.snackbar_text = "No hay documentos para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              console.error("Respuesta no esperada:", response.data);
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al descargar los documentos CCF:", error);
            this.snackbar_text = "No se pudo descargar el archivo";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },


    descargarPERC() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_facturas = true;
        axios.get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            if (Array.isArray(response.data)) {
              const percData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;

                // Filtrar documentos percepción
                return item.percepcion > 0 &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              }).map(item => {
                const cliente = this.getClienteByIdLocal(item.id_cliente);
                if (cliente) {

                  item.nit = cliente.id_identificador == "1" && cliente.documento
                    ? cliente.documento.replace(/-/g, "")
                    : "";

                  item.dui = cliente.id_identificador == "2" && cliente.documento
                    ? cliente.documento.replace(/-/g, "") // DUI sin guiones
                    : "";

                  item.nombre = cliente.nombre || "No disponible";
                } else {
                  item.nit = "N/A"; // Por defecto si no hay cliente
                  item.dui = "N/A"; // Por defecto si no hay cliente
                  item.nombre = "Cliente no encontrado"; // Por defecto si no hay cliente
                }
                return item;
              });

              // Obtener los IDs de facturas
              const id_factura = percData.map(item => item.id_factura);

              if (id_factura.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_facturas: id_factura,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "LOCAL" // LOCAL o SERVER
                };


                axios.post("api/Facturas/ReportePercepcion", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo generado
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reportes_Percepcion_${this.anio}_${this.mes}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte descargado exitosamente";
                    this.snackbar = true;
                  })
                  .catch(error => {
                    console.error("Error al generar el reporte:", error);
                    this.snackbar_text = "No se pudo generar el reporte";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });
              } else {
                console.warn("No hay documentos para el año y mes seleccionados");
                this.snackbar_text = "No hay documentos para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              console.error("Respuesta no esperada:", response.data);
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al descargar los documentos CCF:", error);
            this.snackbar_text = "No se pudo descargar el archivo";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },


    descargarANUL() {
      if (this.$store.state.emp.id_empresa) {
        this.loading_facturas = true;
        axios.get("api/Facturas/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            if (Array.isArray(response.data)) {
              const anulData = response.data.filter(item => {
                const fecha = new Date(item.fecha);
                const itemAnio = fecha.getFullYear();
                const itemMes = fecha.getMonth() + 1;


                return parseFloat(item.resolucion_invalidacion) > 0 &&
                  itemAnio === parseInt(this.anio) &&
                  itemMes === parseInt(this.mes);
              });

              // Obtener los IDs de facturas
              const id_factura = anulData.map(item => item.id_factura);

              if (id_factura.length > 0) {
                // Crear los parámetros para la solicitud
                const parametros = {
                  id_facturas: id_factura,
                  tipo_doc: "pdf", // Tipo Doc
                  server: "LOCAL" // LOCAL o SERVER
                };


                axios.post("api/Facturas/ReporteAnulado", parametros)
                  .then(response => {
                    const reporteURL = response.data;

                    // Descargar el archivo generado
                    const link = document.createElement('a');
                    link.href = reporteURL;
                    link.setAttribute('download', `Reportes_Anulados_${this.anio}_${this.mes}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.base64_doc = "data:application/pdf;base64," + response.data;
                    this.dialog_preview_report = true;

                    this.snackbar_text = "Reporte descargado exitosamente";
                    this.snackbar = true;
                  })
                  .catch(error => {
                    console.error("Error al generar el reporte anulado:", error);
                    this.snackbar_text = "No se pudo generar el reporte anulado";
                    this.snackbar = true;
                  })
                  .finally(() => {
                    this.loading_facturas = false;
                  });
              } else {
                console.warn("No hay documentos anulado para el año y mes seleccionados");
                this.snackbar_text = "No hay documentos anulado para el año y mes seleccionados";
                this.snackbar = true;
                this.loading_facturas = false;
              }
            } else {
              console.error("Respuesta no esperada:", response.data);
              this.snackbar_text = "Error en la respuesta del servidor";
              this.snackbar = true;
              this.loading_facturas = false;
            }
          })
          .catch(error => {
            console.error("Error al descargar los documentos anulado:", error);
            this.snackbar_text = "No se pudo descargar el archivo anulado";
            this.snackbar = true;
            this.loading_facturas = false;
          });
      } else {
        console.error("El id_empresa es undefined");
        this.snackbar_text = "El id_empresa no está definido";
        this.snackbar = true;
      }
    },


    //Generador del Documento CSV-FSE
    generarCSV(data) {
      const rows = data.map(row => {
        const tipoDoc = row.id_tipo_doc_fiscal === "16" ? row.id_tipo_doc_fiscal : "2";

        return [
          tipoDoc,
          row.documento_identificador ? row.documento_identificador.replace(/-/g, "") : "",
          row.nombre || "", // De proveedor
          this.formatDate(row.fecha),
          row.resolucion ? row.resolucion.replace(/-/g, "") : "",
          row.documento ? row.documento.replace(/-/g, "") : "",
          row.internas_importaciones || "0.00",
          row.iva || "0.00",
          row.tipo_operacion || "1",
          row.clasificacion || "2",
          row.rubro || "4",
          row.costo_gastos || "1",
          this.numeroAnexo || "5"
        ].join(";");
      });

      return rows.join("\n");
    },


    generarCSVCCF(data) {
      const rows = data.map(row => [
        this.formatDate(row.fecha), // A: Fecha Emisión (DD/MM/AAAA)
        "4", // B: Clase de Documento
        "03", // C: Tipo de Documento
        row.numero_control ? row.numero_control.replace(/-/g, "") : "", // D: Número de Resolución
        row.sello_recepcion ? row.sello_recepcion.replace(/-/g, "") : "", // E: Número de Serie
        row.codigo_generacion ? row.codigo_generacion.replace(/-/g, "") : "", // F: Número de Documento
        "", // G: Número de Control Interno
        row.nit || "", // H: NIT Cliente sin guiones (Solo si id_identificador === "1")
        row.nombre || "", // I: Nombre Cliente
        row.exentas || "0.00", // J: Ventas Exentas
        row.ventas_no_sujetas || "0.00", // K: Ventas No Sujetas
        row.gravadas || "0.00", // L: Ventas Gravadas
        row.iva || "0.00", // M: Débito Fiscal
        row.ventas_terceros || "0.00", // N: Ventas a Cuenta de Terceros No Domiciliados
        row.debito_fiscal_terceros || "0.00", // O: Débito Fiscal por Venta a Cuenta de Terceros
        row.total_ventas || "0.00", // P: Total Ventas
        row.dui || "", // Q: DUI Cliente (Solo si id_identificador === "2")
        "1" // R: Número de Anexo
      ].join(";"));

      return rows.join("\n");
    },


    generarCSVFAC(data) {

      const rows = data.map(row => {
        const exportacionesDentro = row.id_tipo_exportacion === "REGION CENTROAMERICANA" ? row.gravadas : "0.00";
        const exportacionesFuera = row.id_tipo_exportacion === "FUERA REGION CENTROAMERICANA" ? row.gravadas : "0.00";
        const gravadasLocales = row.id_tipo_exportacion ? "0.00" : row.gravadas;
        const zonaFranca = row.es_zona_franca_dpa ? row.gravadas : "0.00";

        return [
          this.formatDate(row.fecha), // Fecha Emisión
          "4", // Clase Documento
          "01", // Tipo Documento
          "N/A", // Número de Resolución
          "N/A", // Número Serie
          "N/A", // Número de Control Interno DEL
          "N/A", // Número de Control Interno AL
          "N/A", // Número de Documento DEL
          "N/A", // Número de Documento AL
          "", // Número de Maquina Registradora
          row.exentas || "0.00", // Ventas Exentas
          row.ventas_internas_exentas || "0.00", // Ventas Internas Exentas
          row.no_sujetas || "0.00", // Ventas No Sujetas
          gravadasLocales, // Ventas Gravadas Locales
          exportacionesDentro, // Exportaciones Dentro de CA
          exportacionesFuera, // Exportaciones Fuera de CA
          row.exportacion_servicios || "0.00", // Exportaciones de Servicios
          zonaFranca, // Ventas a Zonas Francas Y DPA
          row.venta_terceros || "0.00", // Ventas a Terceros no Domiciliados
          row.total_pagar || "0.00", // Total Ventas
          "2" // Número de Anexo
        ].join(";");
      });

      return rows.join("\n");
    },



    //Generador del Documento CSV-Compras
    generarCSVCompras(data) {
      const rows = data.map(row => {
        // Cálculos para Exentas + No Sujetas si es extranjero
        const exentasNoSujetas = parseFloat(row.internas_importaciones_exentas || "0") + parseFloat(row.no_sujeto || "0");

        // Valores según área y tipo de proveedor
        let comprasInternasExentas = "0.00";
        let internacionesExentas = "0.00";
        let importacionesExentas = "0.00";

        let comprasInternasGravadas = "0.00";
        let internacionesGravadas = "0.00";
        let importacionesGravadas = "0.00";

        if (row.extranjero) {
          if (row.area = "REGION LOCAL") {
            comprasInternasExentas = exentasNoSujetas.toFixed(2);
          } else if (row.area = "IMPORTACION") {
            internacionesExentas = exentasNoSujetas.toFixed(2);
          } else if (row.area = "INTERNACION") {
            importacionesExentas = exentasNoSujetas.toFixed(2);
          }
        } else {
          // Caso de local: usar gravadas
          if (row.area = "REGION LOCAL") {
            comprasInternasGravadas = (row.internas_importaciones || "0.00");
          } else if (row.area = "IMPORTACION") {
            internacionesGravadas = (row.internas_importaciones || "0.00");
          } else if (row.area = "INTERNACION") {
            importacionesGravadas = (row.internas_importaciones || "0.00");
          }
        }

        // Tipo de Documento (columna C)
        const tipoDocumento = (() => {
          if (row.id_tipo_doc_fiscal = "CCF") {
            return "03";
          } else if (row.id_tipo_doc_fiscal = "NCR") {
            return "05";
          } else if (row.id_tipo_doc_fiscal = "NDB") {
            return "06";
          }
        })();

        return [
          this.formatDate(row.fecha), // A: Fecha Emisión (DD/MM/AAAA)
          "4", // B: Clase de Documento
          tipoDocumento, // C: Tipo de Documento
          row.documento ? row.documento.replace(/-/g, "") : "", // D: Número de Documento
          row.nit_ncr || "", // E: NIT del Proveedor (Solo si id_identificador === "1")
          row.proveedor || "", // F: Nombre del Proveedor
          comprasInternasExentas, // G: Compras Internas Exentas/No Sujetas
          internacionesExentas, // H: Internaciones Exentas/No Sujetas
          importacionesExentas, // I: Importaciones Exentas/No Sujetas
          comprasInternasGravadas, // J: Compras Internas Gravadas
          internacionesGravadas, // K: Internaciones Gravadas de Bienes
          importacionesGravadas, // L: Importaciones Gravadas de Bienes
          row.importaciones_gravadas_servicios || "0", // M: Importaciones Gravadas de Servicios
          row.iva || "0.00", // N: Crédito Fiscal
          row.total_pagar || "0.00", // O: Total Compras
          row.dui || "", // P: DUI del Proveedor (Solo si id_identificador === "2")
          row.tipo_operacion || "4", // Q: Tipo de Operación
          row.clasificacion || "", // R: Clasificación
          row.rubro || "", // S: Sector
          row.costo_gastos || "", // T: Tipo de Costo/Gasto
          "3" // U: Anexo
        ].join(";");
      });

      return rows.join("\n");
    },


    //Generador del Documento CSV-RET
    generarCSVRET(data) {
      const rows = data.map(row => [
        row.nit || "", // Campo A: NIT (Solo si id_identificador === "1")
        this.formatDate(row.fecha), // Campo B: Fecha Emisión (DD/MM/AAAA)
        row.tipoDocumento || "07", // Campo C: Tipo de Documento
        row.sello_recepcion || "", // Campo D
        row.codigo_generacion ? row.codigo_generacion.replace(/-/g, "") : "", // Campo E
        row.monto_sujeto || "0.00", // Campo F: Monto Sujeto
        row.monto_retencion || "0.00", // Campo G: Monto Retención
        row.dui || "", // Campo H: DUI (Solo si id_identificador === "2")
        this.numeroAnexo || "7" // Campo I: Anexo
      ].join(";"));

      return rows.join("\n");
    },


    generarCSVPERC(data) {
      const rows = data.map(row => {

        // Tipo de Documento (columna C)
        const tipoDocumento = (() => {
          if (row.id_tipo_doc_fiscal = "CCF") {
            return "03";
          } else if (row.id_tipo_doc_fiscal = "NCR") {
            return "05";
          } else if (row.id_tipo_doc_fiscal = "NDB") {
            return "05";
          }
        })();

        return [
          row.nit || "", // Campo A: NIT (Solo si id_identificador === "1")
          this.formatDate(row.fecha), // Campo B: Fecha Emisión (DD/MM/AAAA)
          tipoDocumento, // C: Tipo de Documento
          row.sello_recepcion || "", // Campo D 
          row.codigo_generacion ? row.codigo_generacion.replace(/-/g, "") : "", // Campo E
          row.monto_sujeto || "0.00", // Campo F: Monto Sujeto
          row.monto_percepcion || "0.00", // Campo G: Monto Percepción
          row.dui || "", // Campo H: DUI (Solo si id_identificador === "2")
          this.numeroAnexo || "8" // Campo I: Anexo
        ].join(";");
      });

      return rows.join("\n");
    },


    generarCSVANUL(data) {
      const rows = data.map(row => {
        const tipoDocumento = (() => {
          if (row.id_tipo_doc_fiscal = "11") {
            return "01";
          } else if (row.id_tipo_doc_fiscal = "10") {
            return "03";
          } else if (row.id_tipo_doc_fiscal = "14") {
            return "06";
          } else if (row.id_tipo_doc_fiscal = "13") {
            return "05";
          } else if (row.id_tipo_doc_fiscal = "15") {
            return "04";
          } else if (row.id_tipo_doc_fiscal = "16") {
            return "14";
          }
        })();

        return [
          (row.numero_control || "").replace(/-/g, "").trim(), // Campo A
          (row.clase_documento || "4").trim(), // Clase Documento B
          (row.desde_impreso || "0").trim(), // Campo C
          (row.hasta_impreso || "0").trim(), // Campo D
          tipoDocumento.trim(), // Campo E
          (row.tipo_detalle || "D").trim(), // Campo F
          (row.resolucion_invalidacion || "").replace(/-/g, "").trim(), // Campo G
          (row.desde || "0").trim(), // Campo H
          (row.hasta || "0").trim(), // Campo I
          (row.codigo_generacion || "").trim() // Campo J
        ].join(";");
      });

      return rows.join("\n");
    },


    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    },

    generarReporte() {
      axios.get('api/Factura/ReporteFAC')
        .then(response => {
          this.items_fac = response.data;
        })
        .catch(error => {
          console.error('Error al generar el reporte:', error);
        });
    },
  }
}
</script>


<style scoped>
#myPDF {
  width: 100%;
  height: 100%;
}


.v-btn {
  width: 190px;
  height: 190px;
  color: white;
}

.button-image {
  width: 140px;
  height: 140px;
}
</style>*/