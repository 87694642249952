<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="items" :loading="loading">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Resumen ventas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-row justify="end">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fecha_desde"
                label="Desde"
                outlined
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fecha_hasta"
                label="Hasta"
                outlined
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn @click="listarResumenes()" color="primary">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:[`item.fecha`]="{ item }">
        <span> {{ formatoFecha(item.fecha) }}</span>
      </template>

      <template v-slot:[`item.total_pagar`]="{ item }">
        <span> $ {{ Number(item.total_pagar).toFixed(2) }}</span>
      </template>

      <template v-slot:[`item.estatus`]="{ item }">
        <v-tooltip
          left
          color="primary"
          v-if="
            item.sello_recepcion == '' && item.resolucion_invalidacion == null
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on"
              >fa-regular fa-floppy-disk</v-icon
            >
          </template>
          <span>GUARDADO</span>
        </v-tooltip>

        <v-tooltip
          left
          color="success"
          v-else-if="
            item.sello_recepcion != '' && item.resolucion_invalidacion == null
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="success" v-bind="attrs" v-on="on"
              >fa-regular fa-circle-check</v-icon
            >
          </template>
          <span>PROCESADO</span>
        </v-tooltip>

        <v-tooltip
          left
          color="error"
          v-else-if="
            item.resolucion_invalidacion != '' &&
            item.resolucion_invalidacion != null
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on"
              >fa-solid fa-ban</v-icon
            >
          </template>
          <span>ANULADO</span>
        </v-tooltip>

        <v-tooltip left color="error" v-else-if="item.estatus == 'ERROR'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on"
              >fa-solid fa-circle-exclamation</v-icon
            >
          </template>
          <span>ERROR</span>
        </v-tooltip>
      </template>

      <template v-slot:[`footer.prepend`] v-if="items.length > 0">
        <v-btn color="green" class="white--text" @click="generarExcel()"
          >Descargar</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import settings from "@/settings/settings";
var XLSX = require("xlsx");

export default {
  data: () => ({
    headers: [
      { text: "Número Control", value: "numero_control" },
      { text: "Fecha", value: "fecha" },
      { text: "Tipo Documento", value: "tipoDocFiscal.cod_doc_fiscal" },
      { text: "Cliente", value: "cliente.nombre" },
      { text: "Total", value: "total_pagar" },
      { text: "Estatus", value: "estatus", align: "center" },
    ],
    items: [],
    fecha_desde:
      new Date().getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      new Date().getDate(),
    fecha_hasta:
      new Date().getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      new Date().getDate(),
    loading: false,
    items_excel: [],
  }),
  created() {
    this.listarResumenes();
  },
  methods: {
    listarResumenes() {
      if (this.fecha_desde && this.fecha_hasta) {
        this.loading = true;
        axios
          .post("api/Facturas/ExportarDocumentos", {
            id_empresa: this.$store.state.emp.id_empresa,
            desde: this.fecha_desde,
            hasta: this.fecha_hasta,
          })
          .then((response) => {
            console.log(response.data);
            this.items = response.data;
            for (var i = 0; i < response.data.length; i++) {
              this.items_excel.push({
                "Número de control": response.data[i].numero_control,
                "Código de generacion": response.data[i].codigo_generacion,
                Fecha: this.formatoFecha(response.data[i].fecha),
                "Sello recepción": response.data[i].sello_recepcion,
                "Fecha recepción": this.formatoFecha(response.data[i].fecha_recepcion),
                Cliente: response.data[i].cliente.nombre,
                "Tipo pago": response.data[i].tipo_pago,
                SubTotal: response.data[i].sub_total,
                Gravadas: response.data[i].gravadas,
                Iva: response.data[i].iva,
                Exentas: response.data[i].exentas,
                "No sujetas": response.data[i].no_sujetas,
                Retención: response.data[i].retencion,
                Percepción: response.data[i].percepcion,
                "Total operación": response.data[i].total_operacion,
                "Total pagar": response.data[i].total_pagar,
              });
            }

            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    generarExcel() {
      let data = XLSX.utils.json_to_sheet(this.items_excel);
      const workbook = XLSX.utils.book_new();
      const filename = "Resumen ventas";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    formatoFecha(item) {
      if (item) {
        return settings.metodos.formatoFechaHora(item);
      } else {
        return "";
      }
    },
  },
};
</script>