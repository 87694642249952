import { render, staticRenderFns } from "./LibrosIVA.vue?vue&type=template&id=ac1b99fe&scoped=true&"
import script from "./LibrosIVA.vue?vue&type=script&lang=js&"
export * from "./LibrosIVA.vue?vue&type=script&lang=js&"
import style0 from "./LibrosIVA.vue?vue&type=style&index=0&id=ac1b99fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac1b99fe",
  null
  
)

export default component.exports